.search-filter-dropdown {
  border-radius: 20px !important;
  border: 2px solid white;
}
.search-filter-dropdown .ant-select-item {
  padding: 4px 20px;
}
.search-filter-dropdown .ant-select-item-option {
  flex-direction: row-reverse;
}
.search-filter-dropdown .ant-select-item-option-content {
  padding-left: 22px;
}
.search-filter-dropdown .ant-select-item-option-selected .ant-select-item-option-content {
  padding-left: 6px;
}
.search-dropdown {
  border: 2px solid white;
  border-radius: 20px !important;
}
.menu-wrapper{
  .ant-collapse{
    .ant-collapse-item{

      .ant-collapse-header{
        padding: 15px 20px;
        font-weight: bold;
        color: #ffffff;
      }
      .ant-collapse-content{
        background-color: rgba(21, 18, 63, 0.9);
        border-top: none;
      }

      &.ant-collapse-item-active{
        .ant-collapse-header{
          color: #ffd745;
        }
      }
    }
  }
}

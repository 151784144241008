.ant-menu-horizontal {
  line-height: 80px;
  @media screen and (max-width: 768px) {
    line-height: 50px;
  }
}
body {
  background: #f6f7fb;
  iframe {
    pointer-events: none;
  }
}

#root,
#root > span {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.text-right {
  text-align: end;
}
.text-center {
  text-align: center;
}

// remove autofill color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px @component-background inset !important;
  -webkit-text-fill-color: @text-color !important;
  transition: all 5000s ease-in-out 0s;
  transition-property: background-color, color;
}

.modal-become-witness {
  text-align: center;
  .ant-modal-confirm-content {
    margin-top: 25px;
  }
  .ant-modal-confirm-btns {
    float: none;
    .ant-btn-primary {
      width: 100%;
    }
  }
}

.ant-affix {
  background-color: #0a0840;
  padding: 15px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  height: 65px !important;
  @media screen and (max-width: 576px) {
    height: 110px !important;
  }
}

.mb-15 {
  margin-bottom: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ant-menu.ant-menu-dark {
  .ant-menu-title-content {
    font-weight: bold;
  }
  .ant-menu-sub {
    background: #0a0829;
  }
  .ant-menu-item {
    > span {
      > a {
        font-weight: bold;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
  .ant-menu-item-selected{
    background-color: transparent;
  }

  &:not(.ant-menu-horizontal){
    .ant-menu-item-selected {
      background-color: transparent;

      .anticon{
        color: inherit;
      }
    }
  }
  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow {
      opacity: 1;
    }
  }
}

.ant-table {
  table {
    border-spacing: 0 6px;
  }
  &-thead {
    text-transform: uppercase;
    > tr > th {
      border: none;
      padding: 5px 16px;
      &::before {
        background-color: transparent !important;
      }
    }
  }
  &-tbody {
    > tr {
      &:hover > td {
        border: 2px solid @primary-color;
      }
      > td {
        border: 2px solid transparent;
        transition: 0.3s;
        &:not(:first-child) {
          border-left: none;
        }
        &:not(:last-child) {
          border-right: none;
        }
        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
  &-row {
    background: @component-background;
    height: 80px;
  }
  &-column-has-sorters:hover {
    background: transparent !important;
  }
  &-cell-fix {
    &-left,
    &-right {
      background: @component-background;
    }
  }
  &-sticky-holder {
    // background: transparent;
  }
}

.ant-pagination {
  &-item {
    a {
      color: @text-color-secondary;
    }
    &-ellipsis {
      color: @text-color-secondary !important;
    }
    &-active {
      border-color: @primary-color;
      a {
        color: @black;
      }
    }
    &:focus-visible, &:hover {
      background-color: @white;
      border-color: @white;
      a {
        color: @black;
      }
    }
  }
  &-prev, &-next {
    .ant-pagination-item-link {
      .anticon {
        color: @text-color-secondary;
      }
    }
    &:focus-visible, &:hover {
      .ant-pagination-item-link {
        background-color: @white;
        border-color: @white;
        .anticon {
          color: @black;
        }
      }
    }
  }
  .ant-select{
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: transparent;
      border-color: @text-color-secondary;
      color: @text-color-secondary;
      &:focus-visible, &:hover {
        background-color: @white;
        border-color: @white;
        color: @black;
      }
    }
    &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: @text-color-secondary;
    }
    &-arrow {
      color: @text-color-secondary;
    }
  }
  &-options-quick-jumper {
    color: @text-color-secondary;
    input {
      background-color: transparent;
      border-color: @text-color-secondary;
    }
  }
}

.ant-select {
  &-dropdown {
    .ant-select-item-group {
      color: #ffffff;
    }
    .ant-select-item-option-selected, .ant-select-item-option-active {
      color: #F7C11A;
      font-weight: normal;
    }
    .ant-select-item-option {
      &:hover {
        color: #F7C11A;
      }
    }
  }
  &-arrow {
    color: @white;
  }
}

.ant-input {
  &:focus, &-affix-wrapper:focus, &-affix-wrapper-focused {
    border-color: @primary-color !important;
  }
  &-number {
    border-color: @component-background;
    &-focused {
      border-color: @primary-color;
    }
  }
}

.ant-notification {
  &-notice {
    &-description, &-with-icon, &-message {
      color: #3a3a3c;
    }
  }
  &-close-x {
    color: #3a3a3c;
  }
}

.ant-empty {
  color: @text-color;
}

.ant-form-item-explain {
  min-height: 0;
}

.ant-picker {
  &-header button {
    color: @text-color;
  }
  &-suffix {
    color: inherit;
  }
  &-cell {
    color: #8081f1;
    &-in-view {
      color: @text-color;
    }
    &-in-range::before {
      background: #262270 !important;
    }
    &-disabled {
      color: #808191;
    }
    &:hover .ant-picker-cell-inner {
      background: @primary-color !important;
    }
  }
  &-today-btn-disabled {
    color: #808191 !important;
  }
  &-separator {
    color: @text-color;
  }
}
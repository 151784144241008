
.ant-menu-submenu-horizontal > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: block;
    position: absolute;
    top: 50%;
    right: -2px;
    width: 10px;
    -webkit-transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: transform .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
  }
  
  .ant-menu-submenu-horizontal > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-horizontal > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    position: absolute;
    right: -10px;
    width: 6px;
    height: 1.5px;
    background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .85)), to(rgba(0, 0, 0, .85)));
    background-image: linear-gradient(90deg, rgba(0, 0, 0, .85), rgba(0, 0, 0, .85));
    border-radius: 2px;
    -webkit-transition: background .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: background .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    transition: background .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1);
    transition: background .3s cubic-bezier(.645, .045, .355, 1), transform .3s cubic-bezier(.645, .045, .355, 1), top .3s cubic-bezier(.645, .045, .355, 1), -webkit-transform .3s cubic-bezier(.645, .045, .355, 1);
    content: "  ";
  }
  
  .ant-menu-submenu-horizontal > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(225deg) translateY(-2px);
    transform: rotate(225deg) translateY(-2px);
  }
  
  .ant-menu-submenu-horizontal > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow:after, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(315deg) translateY(2px);
    transform: rotate(315deg) translateY(2px);
  }
@import '~antd/dist/antd.less';
@import './vars.less';
@import './override.less';
@import './antd-horizontal-menu.less';

@media (min-width: 1200px){
    .ant-col-xl-18 {
        display: block;
        flex: 0 0 89%;
        max-width: 89%;
    }
}
